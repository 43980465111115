html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 11px/1.4 'Open Sans', 'sans-serif';
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f7f2;
}

*::-webkit-scrollbar-track {
  background-color: #c5c5c5;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #313131b6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
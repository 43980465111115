.container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-page {
    .sections {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 60px;
        color: rgba(0, 0, 0, 0.8);

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 75%;
            align-items: flex-start;
            margin-top: 60px;

            hr {
                display: none;
            }

            .col {
                width: 50%;
                padding: 0 20px;

                h2 {
                    font-size: 48px;
                    margin-top: 0;
                }

                h3 {
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                address,
                p {
                    font-size: 16px;
                    font-weight: 500;
                }

                .socials {
                    margin-top: 50px;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    list-style: none;
                    justify-content: flex-start;
                    padding: 0;
                    margin: 20px 40px 10px 0;

                    li {
                        margin-right: 24px;

                        a {
                            display: block;
                            font-size: 30px;
                            transition: all .2s ease;

                        }

                        &:nth-child(1) a:hover svg {
                            color: #0a66c2;
                        }

                        &:nth-child(2) a:hover svg {
                            color: black;
                        }

                        &:nth-child(3) a:hover svg {
                            color: #db436c;
                        }

                        &:nth-child(4) a:hover svg {
                            color: #1c96e8;
                        }
                    }

                    li:hover a {
                        transform: scale(1.2);
                    }
                }

                .col-form {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .row-form {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 24px;
                        padding: 0 12px;

                        label {
                            margin-bottom: 0.5rem;
                            font-size: 18px;
                            font-weight: 600;
                        }

                        .form-input {
                            padding: 4px 15px 4px 0;
                            background-color: transparent;
                            border: none;
                            border-bottom: 2px solid rgba(0, 0, 0, .12);
                            border-radius: 0;
                            color: #000;
                            padding-left: 0 !important;
                            font-size: 1.5rem;
                            font-weight: 600;
                        }

                        .form-input:focus {
                            outline: 0;
                            border-bottom: 2px solid rgba(0, 0, 0, 1);
                        }

                        &.submit-container {
                            display: flex;
                            flex-direction: row;

                            @media (max-width : 700px) {
                                flex-direction: column;
                            }
                        }

                        .btn {
                            color: rgb(255, 255, 255);
                            width: 150px;
                            background-color: black;
                            border: none;
                            padding: 12px 20px;
                            font-size: 16px;

                            &:hover {
                                cursor: pointer;
                            }

                            &:disabled {
                                background-color: rgba(0, 0, 0, 0.7);
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    .contact-page {
        .sections {
            .row {
                width: 90%;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .contact-page {
        .sections {
            .row {
                width: 95%;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 1023px) {
    .contact-page {
        .sections {
            .row {
                width: 95%;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;

                hr {
                    display: block;
                    width: 95%;
                    margin: 24px 0;
                    opacity: 0.5;
                }

                .col {
                    width: 90%;

                    .form-container {
                        margin-bottom: 24px;

                        .col-form {
                            .btn-container {
                                align-items: center;
                                margin-bottom: 30px;
                            }
                        }
                    }
                }

                .contact-details-resp {
                    text-align: center;

                    ul {
                        grid-gap: 30px;
                        justify-content: center;
                        margin-right: 0;

                        li {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 500px) {
    .contact-page {
        .sections {
            .row {
                .contact-details-resp {
                    h2 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}
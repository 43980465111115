.container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.portfolio-page {
    background-color: rgba(0, 0, 0, 0.07);

    .sections {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 60px;
        color: rgba(0, 0, 0, 0.8);
        padding-bottom: 120px;

        .portfolio-projects {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 75%;

            .sub-heading-a {
                margin: 40px 0 0 0;
                background-color: #f8de53;
                padding: 3px 8px;
                font-size: 1.6rem;
                font-weight: 600;
                border-radius: 3px;
            }

            .sub-heading-b {
                margin: 10px 0 36px 0;
                font-size: 4.2rem;
            }

            ul {
                list-style: none;
                display: flex;
                padding: 0;

                li {
                    button {
                        background: transparent;
                        border: none;
                        padding: .6rem 1rem;
                        position: relative;
                        white-space: nowrap !important;
                        font-size: 1.6rem;
                        font-weight: 700;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .active {
                        text-decoration: none;
                        color: rgba(0, 0, 0, 1);
                        border-bottom: 3px solid #f8de53;
                    }

                    button:hover {
                        color: rgba(0, 0, 0, 1);
                        cursor: pointer;
                    }
                }
            }

            .project-cards {
                display: flex;
                grid-gap: 45px;
                flex-wrap: wrap;
                flex-direction: row;
                margin-top: 60px;
                width: 100%;
            }
        }
    }
}


@media (min-width: 1600px) and (max-width: 1919px) {
    .portfolio-page {
        .sections {
            .portfolio-projects {
                width: 80%;

                .project-cards {
                    grid-gap: 44px;
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1599px) {
    .portfolio-page {
        .sections {
            .portfolio-projects {
                width: 90%;
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 1023px) {
    .portfolio-page {
        .sections {
            .portfolio-projects {
                width: 90%;

                .project-cards {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 599px) {
    .portfolio-page {
        .sections {
            .portfolio-projects {
                .sub-heading-b {
                    text-align: center;
                    font-size: 3.6rem;
                }

                ul {
                    li:first-child {
                        display: none;
                    }
                }

                width: 90%;

                .project-cards {
                    flex-direction: column;
                    align-items: center;
                    grid-gap: 36px;
                }
            }
        }
    }
}

@media (max-width: 359px) {
    .portfolio-page {
        .sections {
            .portfolio-projects {
                ul {
                    li {
                        button {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}
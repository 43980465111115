.container {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

.home-page {
    background-color: rgba(0, 0, 0, 0.07);
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .top-sec {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        color: rgb(0, 0, 0);


        .text-zone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60%;

            .greet-name {
                margin-left: 100px;
                font-size: 5.5rem;
                letter-spacing: 1px;
                text-transform: capitalize;
            }

            .animated-text {
                color: #115173;
                font-size: 8rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-left: 100px;
            }

            .contact-info {
                margin-left: 100px;
                list-style: none;
                padding: 0;
                font-size: 2rem;
                letter-spacing: 1px;
                line-height: 40px;

                a {
                    text-decoration: none;
                    color: rgb(0, 0, 0);

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .icon {
                    margin-right: 20px;
                }
            }

            .buttons {
                margin: 40px 0 0 100px;
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    text-decoration: none;
                }

                .resume-btn {
                    border: 2px solid rgba(0, 0, 0, 0.75);
                    color: rgba(0, 0, 0, 0.75);
                    margin-right: 30px;
                    padding: 10px 36px;
                    font-size: 2rem;
                    letter-spacing: 1px;
                    border-radius: 3px;
                    transition: all ease 250ms;

                    .download-icon {
                        margin-right: 12px;
                    }

                }

                .contact-btn {
                    text-decoration: underline;
                    color: rgb(0, 0, 0);
                    padding: 5px 15px;
                    font-size: 2rem;
                    font-weight: 700;
                    letter-spacing: 1px;
                    transition: all ease 320ms;
                }

                .resume-btn:hover {
                    color: rgba(255, 255, 255, 0.9);
                    border-color: rgba(17, 81, 115, 0.2);
                    background-color: #115173;
                    transition: all ease 350ms;
                }

                .contact-btn:hover {
                    transition: all ease 350ms;
                    text-decoration: none;
                }
            }
        }

        .image-zone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;

            img {
                width: 500px;
                height: 500px;
                border: 3px solid rgba(255, 255, 255, 0.747);
                box-shadow: 10px 10px 20px 0px #000000;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}

@media (min-width: 1600px) and (max-width: 1919px) {
    .home-page {
        .top-sec {
            .text-zone {
                .greet-name {
                    font-size: 5rem;
                }

                .animated-text {
                    font-size: 6.75rem;
                }
            }

            .image-zone {
                img {
                    width: 475px;
                    height: 475px;
                }
            }
        }
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    .home-page {
        .top-sec {
            .text-zone {
                .greet-name {
                    font-size: 4.5rem;
                }

                .animated-text {
                    font-size: 5.75rem;
                }
            }

            .image-zone {
                img {
                    width: 450px;
                    height: 450px;
                }
            }
        }
    }
}


// for small laptops
@media (min-width: 1024px) and (max-width: 1439px) {
    .home-page {
        .top-sec {
            .text-zone {
                width: 65%;

                .greet-name {
                    font-size: 3.5rem;
                    margin-left: 50px;
                }

                .animated-text {
                    margin-left: 50px;
                    font-size: 4.5rem;
                }

                .contact-info,
                .buttons {
                    margin-left: 50px;
                }
            }

            .image-zone {
                width: 35%;

                img {
                    width: 300px;
                    height: 300px;
                    margin-right: 50px;
                }
            }
        }
    }
}

// for tablets
@media (min-width: 600px) and (max-width: 1023px) {
    .home-page {
        overflow-y: auto;

        .top-sec {
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin-top: 60px;
            align-items: center;

            .text-zone {
                width: 100%;
                // height: 50%;
                align-items: center;
                margin-bottom: 30px;
                justify-content: space-evenly;

                .greet-name {
                    font-size: 3.5rem;
                    margin: 0;
                }

                .animated-text {
                    margin: 0;
                    font-size: 4.25rem;
                    font-weight: 700;
                }

                .contact-info {
                    margin-left: 0;
                }

                .buttons {
                    margin: 0 0 30px;

                    .resume-btn {
                        border: none;
                        width: 140px;
                        color: rgba(255, 255, 255, 0.9);
                        background-color: #115173;
                        padding: 12px 36px;
                        text-align: center;
                        font-weight: 500;
                    }

                    .contact-btn {
                        width: 140px;
                        text-decoration: none;
                        color: rgba(255, 255, 255, 0.9);
                        background-color: #115173;
                        padding: 12px 36px;
                        font-size: 2rem;
                        letter-spacing: 1px;
                        border-radius: 3px;
                        transition: all ease 320ms;
                        font-weight: 500;
                    }
                }
            }

            .image-zone {
                width: 100%;
                margin: 60px 0 30px;

                img {
                    width: 375px;
                    height: 375px;
                    box-shadow: 5px 5px 20px 0px #000000;
                }
            }
        }
    }
}


// for mobiles
@media (min-width: 320px) and (max-width: 599px) {
    .home-page {
        overflow-y: auto;

        .top-sec {
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin-top: 60px;
            align-items: center;

            .text-zone {
                width: 100%;
                align-items: center;
                margin-top: 12px;

                .greet-name {
                    font-size: 2.5rem;
                    margin: 0 0 5% 0;
                }

                .animated-text-container {
                    margin-bottom: 5%;

                    .animated-text {
                        margin: 0;
                        font-size: 2.2rem;
                        font-weight: 800;
                    }
                }

                .contact-info {
                    margin: 0;
                    font-size: 1.6rem;
                    line-height: 30px;
                }

                .buttons {
                    margin: 20px 0 30px;
                    // position: absolute;
                    // bottom: 30px;

                    .resume-btn {
                        border: none;
                        width: 100px;
                        color: rgba(255, 255, 255, 0.9);
                        background-color: #115173;
                        padding: 12px 20px;
                        text-align: center;
                        font-weight: 500;
                        font-size: 1.4rem;
                    }

                    .contact-btn {
                        width: 100px;
                        text-decoration: none;
                        color: rgba(255, 255, 255, 0.9);
                        background-color: #115173;
                        padding: 12px 20px;
                        font-size: 1.4rem;
                        letter-spacing: 1px;
                        border-radius: 3px;
                        transition: all ease 320ms;
                        font-weight: 500;
                    }
                }
            }

            .image-zone {
                width: 65%;
                margin: 5% 0 20px;

                img {
                    width: 24rem;
                    height: 24rem;
                    box-shadow: 5px 5px 20px 0px #000000;
                }
            }
        }
    }
}

@media (min-width: 400px) and (max-width: 599px) {
    .home-page {
        .top-sec {
            .text-zone {
                margin-top: 30px;
            }

            .image-zone {
                img {
                    height: 27.5rem;
                    width: 27.5rem;
                }
            }
        }
    }
}

@media (max-width: 359px) {
    .home-page {
        .top-sec {
            .text-zone {
                .animated-text-container {
                    .animated-text {
                        font-size: 2rem;
                    }
                }
            }

            .image-zone {
                img {
                    width: 21rem;
                    height: 21rem;
                }
            }
        }
    }
}
.nav-bar {
    display: flex;
    align-items: center;
    background: #f8f7f2;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 3;
    min-width: 500px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    .logo {
        display: block;
        padding: 4px 8px 0 8px;
        margin-left: 84px;

        img {
            display: block;
            margin: -5px auto;
            width: 65px;
            height: auto;
        }
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        right: 50%;

        @media (max-width: 1000px) {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            background-color: #f8f7f2;
            padding: 20px 0;
            width: 100%;
            max-height: 0;
            grid-gap: 10px;
            box-shadow: 0px 20px 20px -10px rgb(0, 0, 0, 0.3);
        }

        &.mobile-view-nav {
            display: flex;
            max-height: 200px;
        }

        a {
            font-size: 20px;
            color: black;
            display: block;
            margin: 0 12px;
            padding: 3px 0;
            position: relative;
            text-decoration: none;
            letter-spacing: 1px;
            opacity: 0.7;
            transition: all .15s ease;

            &:hover {
                opacity: 1;
                transform: scale(1.07);
            }

            &::before {
                transition: 250ms;
                height: 2px;
                content: "";
                position: absolute;
                background-color: #115173;
                width: 100%;
                bottom: -5px;
                opacity: 0;
            }

            &:hover::before {
                bottom: -1px;
                opacity: 1;
            }

            &.active::before {
                bottom: -1px;
                opacity: 0;
            }
        }
    }

    a.active {
        padding-top: 3px;
        opacity: 1;
        transform: scale(1.07);
        font-weight: 600;
        // color: #ecc900;
    }

    .nav-toggle {
        position: absolute;
        right: 180px;
        display: block;
        z-index: 3;
        -webkit-user-select: none;
        user-select: none;
        top: 22px;

        input {
            display: block;
            width: 25px;
            height: 19px;
            top: -5px;
            right: -5px;
            position: absolute;
            cursor: pointer;
            opacity: 0;
            z-index: 4;
            -webkit-touch-callout: none;
        }

        span {
            display: block;
            width: 22px;
            height: 3px;
            margin-bottom: 3px;
            position: relative;

            background-color: rgba(0, 0, 0, 0.72);
            border-radius: 10px;
            z-index: 3;

            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
        }

        span:first-child {
            transform: 0% 0%;
        }

        span:nth-last-child(2) {
            transform-origin: 0% 100%;
        }

        input:checked~span {
            opacity: 1;
            transform: rotate(-45deg) translate(-1px, -1px);
        }

        input:checked~span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        input:checked~span:nth-last-child(2) {
            transform: rotate(45deg) translate(-5px, -6px);
        }


    }

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        justify-content: center;
        padding: 0;
        margin-right: 40px;
        position: absolute;
        right: 0;

        li {
            margin: 0 7px;

            a {
                display: block;
                font-size: 20px;
                transition: all .2s ease;

            }

            &:nth-child(1) a:hover svg {
                color: #0a66c2;
            }

            &:nth-child(2) a:hover svg {
                color: black;
            }

            &:nth-child(3) a:hover svg {
                color: #db436c;
            }

            &:nth-child(4) a:hover svg {
                color: #1c96e8;
            }
        }

        li:hover a {
            transform: scale(1.2);
        }
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .nav-bar {
        min-width: 300px;

        .logo {
            margin-left: 40px;
        }
    }
}

@media (max-width: 599px) {
    .nav-bar {
        min-width: 300px;

        .logo {
            margin-left: 12px;
        }

        .nav-toggle {
            right: 150px;
        }

        ul {
            margin-right: 12px;
        }
    }
}
.project-card {
    background-color: #f8f7f2af;
    width: 29%;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 5px 15px 2px #c2c2c2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.03);
    }

    h3 {
        margin: 15px 0 30px;
        font-size: 2rem;
        text-align: center;
    }

    img {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 15px;
        height: 250px;
        object-fit: cover;
    }

    .tech-stack {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 6px;

        .tech-item {
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-color: rgba(0, 0, 0, 0.07);
            padding: 4px 12px;
            border-radius: 12px;
            font-size: 1.2rem;
            font-weight: 600;
            transition: "background-color 0.3s ease, transform 0.3s ease";
            cursor: default;
            margin-right: 6px;
        }
    }

    p {
        font-size: 1.5rem;
    }

    .options {
        display: flex;
        flex-direction: row;
        grid-gap: 15px;
        justify-content: flex-end;
        margin-top: 8px;

        a,
        button {
            display: flex;
            align-items: center;
            font-size: 2rem;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.8);

            &:hover {
                background-color: #ccc;
                color: rgba(0, 0, 0, 1);
            }
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        button {
            background-color: transparent;
            font-size: 2.4rem;
            border: none;
            outline: none;
        }
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .project-card {
        width: 28.5%;

        h3 {
            text-wrap: pretty;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .project-card {
        width: 44%;
    }
}

@media (min-width: 600px) and (max-width: 1023px) {
    .project-card {
        width: 70%;
    }
}

@media (min-width: 320px) and (max-width: 599px) {
    .project-card {
        width: 90%;

        &:hover {
            transform: none;
        }

        h3 {
            text-wrap: pretty;
        }

        img {
            height: 180px;
        }
    }
}
.project-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .modal-content {
        background-color: white;
        padding: 20px;
        width: 75%;
        max-height: 85%;
        overflow-x: hidden;
        position: relative;


        .modal-header {
            .close-button {
                position: absolute;
                top: 10px;
                right: 20px;
                cursor: pointer;
                font-size: 2rem;
                color: rgba(0, 0, 0, 0.5);

                &:hover {
                    color: rgba(0, 0, 0, 0.8);
                }
            }

            h2 {
                text-align: center;
                font-size: 2.2rem;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }

        .flex-row {
            display: flex;
            flex-direction: row;
            align-items: center;

            .image-carousel {
                width: 50%;
                padding: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .project-details {
                width: 50%;
                padding: 20px;
                max-height: 600px;
                overflow-y: auto;

                h4 {
                    font-size: 2rem;
                    margin: 0 0 12px;
                    font-weight: 600;
                    border-top: 1px solid #ccc;
                    padding-top: 8px;
                }

                p {
                    font-size: 1.4rem;
                    font-weight: 500;
                    text-align: justify;
                }

                ul {
                    flex-direction: column;
                    list-style: circle;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 36px;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 12px;
                }

                .options {
                    display: flex;
                    flex-direction: row;
                    grid-gap: 15px;
                    justify-content: flex-start;
                    margin-top: 8px;

                    a,
                    button {
                        display: flex;
                        align-items: center;
                        font-size: 2rem;
                        padding: 10px;
                        border-radius: 4px;
                        transition: background-color 0.3s ease;
                        cursor: pointer;
                        color: rgba(0, 0, 0, 0.8);

                        &:hover {
                            background-color: #ccc;
                            color: rgba(0, 0, 0, 1);
                        }
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                    }

                    button {
                        background-color: transparent;
                        font-size: 2.2rem;
                        border: none;
                        outline: none;
                    }
                }
            }

            .project-details::-webkit-scrollbar {
                width: 3px;
                background-color: #F5F5F5;
            }
        }
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    .project-modal {
        .modal-content {
            width: 85%;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .project-modal {
        .modal-content {
            width: 90%;
        }
    }
}

@media (min-width: 600px) and (max-width: 1023px) {
    .project-modal {
        .modal-content {
            width: 90%;
            padding: 0;

            .modal-header {
                background: white;
                // height: 75px;
                position: fixed;
                width: 90%;
                box-shadow: 0px 5px 20px -10px gray;

                .close-button {
                    font-size: 2.8rem;
                }

                h2 {
                    text-wrap: pretty;
                    padding: 0 40px;
                }
            }

            .flex-row {
                flex-direction: column;
                margin-top: 65px;
                padding: 20px;

                .image-carousel {
                    width: 90%;
                    height: 220px;
                }

                .project-details {
                    width: 90%;
                    height: 100%;
                    max-height: 100%;
                    overflow: hidden;

                    .options {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 599px) {
    .project-modal {
        .modal-content {
            width: 100%;
            max-height: 100%;
            height: 100%;
            padding: 0;

            .modal-header {
                background: white;
                // height: 75px;
                position: fixed;
                width: 100%;
                box-shadow: 0px 5px 20px -10px gray;

                .close-button {
                    font-size: 2.8rem;
                }

                h2 {
                    text-wrap: pretty;
                    padding: 0 40px;
                }
            }

            .flex-row {
                flex-direction: column;
                margin-top: 90px;
                padding: 10px;

                .image-carousel {
                    width: 90%;
                    height: 220px;
                }

                .project-details {
                    width: 90%;
                    height: 100%;
                    max-height: 100%;
                    overflow: hidden;

                    .options {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}
.container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-page {
    .sections {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 60px;
        color: rgba(0, 0, 0, 0.8);

        .sub-heading-a {
            margin: 40px 0 0 0;
            background-color: #f8de53;
            padding: 3px 8px;
            font-size: 1.6rem;
            font-weight: 600;
            border-radius: 3px;
            text-align: center;
        }

        .sub-heading-b {
            text-align: center;
            margin: 10px 0 0 0;
            font-size: 4.2rem;
        }

        .about-me {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.07);
            padding-bottom: 100px;
            width: 100%;

            .about-me-container {
                width: 75%;

                .experience {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .summary {
                        width: 75%;

                        h2 {
                            font-size: 3rem;
                            font-weight: 400;

                            span {
                                border-bottom: 3px solid #f8de53;
                                font-weight: 700;
                                padding-bottom: 4px;
                            }
                        }

                        p {
                            font-size: 2rem;
                            line-height: 2;

                            span {
                                font-weight: 700;
                            }
                        }
                    }

                    .yoe {
                        display: inline-block;
                        position: relative;
                        align-content: center;

                        h1 {
                            width: 80px;
                            height: 80px;
                            line-height: 6.2rem;
                            text-align: center;
                            position: relative;
                            z-index: 1;
                            font-size: 12rem;
                            margin: 20px 0;
                        }

                        h1::after {
                            content: "";
                            position: absolute;
                            // top: 50%;
                            // left: 106%;
                            top: 0;
                            left: 56%;
                            // transform: translate(-50%, -50%);
                            width: 100%;
                            height: 100%;
                            background-color: #f8de53;
                            border-radius: 50%;
                            z-index: -1;
                            box-shadow: 0 0 5px 3px #f8de53;
                            transition: all .2s ease;
                        }

                        h1:hover::after {
                            transform: scale(1.4);
                        }

                        h3 {
                            text-align: center;
                            font-size: 2rem;
                            font-weight: 400;
                            margin: 0;

                            span {
                                font-weight: 700;
                            }
                        }
                    }
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    list-style: none;
                    margin-top: 80px;
                    padding: 0;

                    li {
                        p {
                            font-size: 1.6rem;
                            font-weight: 400;
                            margin: 0;
                        }

                        h4 {
                            font-size: 1.8rem;
                            font-weight: 700;
                            margin: 15px 0 0 0;

                            a {
                                color: rgba(0, 0, 0, 0.8);
                            }
                        }
                    }
                }
            }
        }

        .resume {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 100px;
            width: 100%;

            .work-exp {
                margin: 60px 0 0 0;
                display: flex;
                flex-direction: row;
                width: 75%;
                align-items: flex-start;

                .education,
                .experience {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0 25px;

                    h1 {
                        margin: 0 0 20px 0;
                        font-size: 3rem;
                        font-weight: 700;
                        width: 100%;
                    }

                    .items {
                        border-left: 2px solid #f8de53;
                        padding-left: 14px;
                        width: 100%;

                        .exp-card-container {
                            h2 {
                                margin: 0 0 10px 0;
                                font-size: 2rem;
                                font-weight: 600;
                                width: 100%;
                            }

                            .head2 {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                h3 {
                                    margin: 0 0 10px 0;
                                    padding: 0;
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                }
                            }

                            ul {
                                list-style: none;
                                padding: 0;
                                font-size: 1.4rem;
                                font-weight: 400;
                                line-height: 2rem;
                                margin: 15px 0 0 0;

                                li {
                                    margin: 5px 0;
                                }
                            }

                            hr {
                                opacity: 0.2;
                                margin: 2rem 0;
                            }
                        }

                        .exp-card-container:last-child hr {
                            margin-bottom: 0;

                        }
                    }
                }
            }

            .skills {
                margin: 60px 0 0 0;
                display: flex;
                flex-direction: column;
                width: 75%;

                h1 {
                    color: rgb(0, 0, 0);
                    font-size: 3.2rem;
                    margin-bottom: 30px;
                }

                .skills-list {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .skills-sec {
                        width: 45%;
                    }
                }

                .card-container {

                    margin-bottom: 28px;

                    div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;

                        h4 {
                            margin: 0;
                            font-size: 1.6rem;
                            font-weight: 700;
                        }

                        span {
                            font-size: 1.2rem;
                            font-weight: 700;
                        }
                    }
                }
            }

            .resume-btn {
                text-decoration: none;
                border: 2px solid rgba(0, 0, 0, 0.75);
                color: rgba(0, 0, 0, 0.75);
                margin-top: 40px;
                padding: 12px 32px;
                font-size: 1.8rem;
                font-weight: 500;
                letter-spacing: 1px;
                border-radius: 3px;
                transition: all ease 250ms;
            }

            .resume-btn:hover {
                color: rgb(255, 255, 255);
                background-color: rgba(0, 0, 0, 0.9);
                transition: all ease 350ms;
            }
        }

        .contact-container {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: 450px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.75);
                z-index: 1;
            }

            .content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                text-align: center;
                z-index: 2;

                h1 {
                    font-size: 5rem;
                    font-weight: 600;
                    margin-bottom: 80px;
                }

                a {
                    text-decoration: none;
                    background-color: #f8de53;
                    padding: 15px 40px;
                    font-size: 1.8rem;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.8);
                }
            }
        }
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    .about-page {
        .sections {
            .about-me {
                .about-me-container {
                    width: 90%;
                }
            }

            .resume {
                .work-exp {
                    width: 90%;
                }

                .skills {
                    width: 90%;
                }
            }

            .contact-container {
                .content {
                    h1 {
                        font-size: 4.75rem;
                    }
                }
            }
        }
    }
}

// for small laptops
@media (min-width: 1024px) and (max-width: 1439px) {
    .about-page {
        .sections {
            .about-me {
                .about-me-container {
                    width: 90%;
                }
            }

            .resume {
                .work-exp {
                    width: 90%;
                }

                .skills {
                    width: 90%;

                    .skills-list {
                        .skills-sec {
                            width: 47%;
                        }
                    }
                }
            }

            .contact-container {
                img {
                    height: 360px;
                }

                .content {
                    h1 {
                        font-size: 3.25rem;
                    }
                }
            }
        }
    }
}

// for tablets
@media (min-width: 600px) and (max-width: 1023px) {
    .about-page {
        .sections {
            .about-me {
                .about-me-container {
                    width: 90%;

                    .experience {
                        flex-direction: column;

                        .summary {
                            width: 100%;

                            h2 {
                                text-align: center;
                            }

                            p {
                                text-align: justify;
                            }
                        }

                        .yoe {
                            margin-top: 24px;

                            h1 {
                                margin: 20px 42px;
                            }

                            h3 {
                                font-size: 2.75rem;
                                margin-top: 40px;
                            }
                        }
                    }

                    ul {
                        flex-wrap: wrap;

                        li {
                            width: 50%;
                            margin-top: 24px;
                        }
                    }
                }
            }

            .resume {
                .work-exp {
                    width: 90%;
                    flex-wrap: wrap;
                    margin-top: 30px;

                    .education,
                    .experience {
                        width: 100%;
                        margin-top: 50px;
                        padding: 0 10px;
                    }
                }

                .skills {
                    width: 90%;

                    .skills-list {
                        .skills-sec {
                            width: 47%;
                        }
                    }
                }
            }

            .contact-container {
                img {
                    height: 360px;
                }

                .content {
                    h1 {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
}

// for mobiles
@media (min-width: 320px) and (max-width: 599px) {
    .about-page {
        .sections {
            .sub-heading-b {
                font-size: 3.6rem;
            }

            .about-me {
                .about-me-container {
                    width: 90%;

                    .experience {
                        flex-direction: column;

                        .summary {
                            width: 100%;

                            h2 {
                                text-align: center;
                            }

                            p {
                                display: none;
                            }
                        }

                        .yoe {
                            margin-top: 24px;

                            h1 {
                                margin: 20px 42px;
                            }

                            h3 {
                                font-size: 2.75rem;
                                margin-top: 40px;
                            }
                        }
                    }

                    ul {
                        margin-top: 20px;
                        flex-direction: column;
                        align-items: center;

                        li {
                            text-align: center;
                            width: 100%;
                            margin-top: 36px;
                        }
                    }
                }
            }

            .resume {
                .work-exp {
                    width: 90%;
                    flex-wrap: wrap;
                    margin-top: 30px;

                    .education,
                    .experience {
                        width: 100%;
                        margin-top: 50px;
                        padding: 0 10px;

                        .items {
                            .exp-card-container {
                                .head2 {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }

                .skills {
                    width: 90%;

                    .skills-list {
                        flex-wrap: wrap;

                        .skills-sec {
                            width: 100%;
                        }
                    }
                }
            }

            .contact-container {
                img {
                    height: 270px;
                }

                .content {
                    h1 {
                        font-size: 2.5rem;
                        margin-bottom: 48px;
                    }
                }
            }
        }
    }
}